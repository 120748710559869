<template>
	<div>
		<div class="dialog">
			<el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px">
				<el-form-item label="姓名" prop="name">
					<span class="xx"></span>
					<el-input type="text" clearable v-model="ruleForm.name" placeholder=""></el-input>
				</el-form-item>
				<el-form-item label="手机号码" prop="name">
					<span class="xx"></span>
					<el-input type="text" clearable v-model="ruleForm.phone" placeholder=""></el-input>
				</el-form-item>
				<el-form-item label="反馈内容" prop="name">
					<span class="xx"></span>
					<el-input type="textarea" clearable v-model="ruleForm.content" placeholder=""></el-input>
				</el-form-item>
				<div class="dialog-title">反馈图片</div>
				<el-form-item label="">
					<div class="dialog-up">
						<!-- 预览图 -->
						<div class="dialog-img-box" v-for="(item,index) in previewlist" :key="index">
							<el-image class="dialog-img" :src="item" :preview-src-list="previewlist"
								:initial-index="index" fit="fill">
							</el-image>
						</div>
					</div>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import {
		request,
		api
	} from "@/api/feedback";
	import {
		ElMessage
	} from 'element-plus'

	export default {
		props: ['title', 'mid'],
		data() {
			return {
				previewlist: [],
				ruleForm: {},
			}
		},
		mounted() {
			this.getuser()
		},
		methods: {
			// 获取信息
			getuser() {
				this.$http.post(api.read, {
						id: this.mid
					})
					.then((res) => {
						if (res.code == 1) {
							this.ruleForm = res.data
							this.previewlist = res.data.pic_list.split("|")
						}
					})
			},
		}
	}
</script>

<style scoped lang="scss">
	.dialog-up {
		button {
			margin-bottom: 10px;
		}

		.top {
			font-size: 12px;
			position: absolute;
			top: 0;
			height: 20px;
			line-height: 20px;
			right: 0;

			i {
				padding: 3px;
				cursor: pointer;
				font-size: 14px;
				margin-left: 5px;
				color: #FFFFFF;
				background: rgba(0, 0, 0, 0.5);
			}
		}
	}

	.dialog-title {
		margin: 40px 0;
		font-size: 16px;
	}

	.dialog-up {
		margin-top: 7px;
	}

	.dialog-upf {
		margin-top: 7px;
	}

	.seeupload {
		margin-right: 20px;
	}

	.seeupload span {
		cursor: pointer;
		font-size: 12px;
	}

	.seeupload span:hover {
		color: #5473E8;
	}

	.dialog-img-box {
		position: relative;
		height: 80px;
		overflow: hidden;
		width: 80px;
		margin-right: 15px;
		border: 1px solid #dcdde0;
		border-radius: 4px;
		text-align: center;
	}

	.dialog-img-box p {
		font-size: 12px;
		cursor: pointer;
		width: 80px;
		position: absolute;
		bottom: 0;
		height: 20px;
		line-height: 20px;
		left: 0;
		text-align: center;
		background: rgba(0, 0, 0, 0.5);
		color: #FFFFFF;
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		line-clamp: 1;
		-webkit-box-orient: vertical;
	}
</style>
