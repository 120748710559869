import request from './request'

const api = {
	// 地区列表
	lists: '/Feedback/index',
	// 删除
	dele: '/Feedback/delete',
	// 地区详情
	read: '/Feedback/read',
}

export {
	request,
	api
}
